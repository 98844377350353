"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
class WoocommerceProductData extends component_1.Component {
    constructor(element) {
        super(element);
        this.registerHandlers();
    }
    registerHandlers() {
        this.element.addEventListener('woocommerce_variations_loaded', () => {
            window.acf.doAction('ready');
        });
    }
}
exports.default = WoocommerceProductData;
WoocommerceProductData.selector = '#woocommerce-product-data';
