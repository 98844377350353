"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Burger Framework class
 *
 * receives an array of {@link Component} classes and creates an instance of each.
 * created objects are attached to whatever element was specified by their 'selector' property.
 *
 * @see IComponent
 * @see Component
 */
class Framework {
    constructor(components) {
        this.components = [];
        this.components = components;
        this.bootComponents();
    }
    /**
     * Add and boot a new component
     */
    addComponent(component) {
        this.components.push(component);
        this.bootComponent(component);
    }
    /**
     * After dom content is loaded loop through all registered components and boot them
     */
    bootComponents() {
        window.addEventListener('DOMContentLoaded', () => {
            this.components.forEach((component) => this.bootComponent(component));
        });
    }
    /**
     * Boots a specific component by finding all matching elements and newing an instance of it
     */
    bootComponent(component) {
        // for each element that matches the selector defined in the parent classes selector property
        const selectors = document.querySelectorAll(component.selector);
        // console.log(component.selector)
        // console.log(document.querySelectorAll(component.selector))
        selectors.forEach(el => {
            // console.log(component)
            new component(el);
        });
    }
}
exports.default = Framework;
