"use strict";
/**
 * Docs: https://swiperjs.com/api/
 *
 *  Slider is modularised for optimal filesize. To use
 *  additional features, import each module individually and add them to the 'use'
 *  statement.
 *
 *
 *  Modules list: https://swiperjs.com/api/#custom-build
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = __importStar(require("swiper/core"));
core_1.default.use([core_1.Navigation, core_1.Pagination]);
/**
 * Create a new Swiper Slider instance, with bannerElem as the wrapped container DOM object.
 * To customise/override options or install additional Swiper modules, import them as normal and
 * pass them as additional options inside a JSON object (see {@link HeroBanner} for an example)
 *
 * @see {@link SwiperOptions}
 *
 * @param bannerElem - The wrapping container
 * @param options - Swiper options object
 */
function createSwiper(bannerElem, options = {}) {
    const slideCount = bannerElem.getElementsByClassName('swiper-slide').length;
    const nextElement = bannerElem.querySelector('.swiper-button-next');
    const prevElement = bannerElem.querySelector('.swiper-button-prev');
    const paginationElement = bannerElem.querySelector('.swiper-pagination');
    const defaultOptions = {
        loop: slideCount > 1,
        watchOverflow: true,
        speed: 750,
        autoHeight: false,
        pagination: {
            el: paginationElement,
            clickable: true,
        },
        navigation: {
            nextEl: nextElement,
            prevEl: prevElement,
        },
    };
    const merged = Object.assign(Object.assign({}, defaultOptions), options);
    return new core_1.default(bannerElem, merged);
}
exports.default = createSwiper;
