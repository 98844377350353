"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
class header extends component_1.Component {
    constructor(element) {
        super(element);
        this.searchButtonSelector = "#searchButton";
        this.closeOverlaySelector = ".closeSearchOverlay";
        this.togglerButton = ".navbar-toggler";
        this.searchOverlay();
        this.scrollHandler();
        this.domContentLoadedHandler();
    }
    domContentLoadedHandler() {
        console.log("1");
        if (document.readyState !== "loading") {
            console.log("2");
            this.handleScrollClasses();
        }
        else {
            console.log("3");
            document.addEventListener("DOMContentLoaded", this.handleScrollClasses.bind(this));
        }
    }
    searchOverlay() {
        const headerEl = this.element;
        const searchButtonEl = headerEl.querySelector(this.searchButtonSelector);
        const closeButtonEl = headerEl.querySelector(this.closeOverlaySelector);
        const togglerButtonEl = headerEl.querySelector(this.togglerButton);
        const searchOverlayEl = document.getElementById("searchOverlay");
        closeButtonEl === null || closeButtonEl === void 0 ? void 0 : closeButtonEl.addEventListener("click", function (e) {
            searchOverlayEl === null || searchOverlayEl === void 0 ? void 0 : searchOverlayEl.classList.remove("show");
            e.preventDefault();
            return false;
        });
        searchButtonEl === null || searchButtonEl === void 0 ? void 0 : searchButtonEl.addEventListener("click", function (e) {
            var _a;
            searchOverlayEl === null || searchOverlayEl === void 0 ? void 0 : searchOverlayEl.classList.add("show");
            (_a = searchOverlayEl === null || searchOverlayEl === void 0 ? void 0 : searchOverlayEl.querySelector('[type="text"]')) === null || _a === void 0 ? void 0 : _a.focus();
            e.preventDefault();
            return false;
        });
        togglerButtonEl === null || togglerButtonEl === void 0 ? void 0 : togglerButtonEl.addEventListener("click", function (e) {
            if (!headerEl.classList.contains(header.scrollClass) &&
                !headerEl.classList.contains(header.mobileMenuClass)) {
                headerEl.classList.add(header.mobileMenuClass);
            }
            else {
                if (headerEl.classList.contains(header.mobileMenuClass)) {
                    headerEl.classList.remove(header.mobileMenuClass);
                }
            }
            e.preventDefault();
            return false;
        });
    }
    scrollHandler() {
        window.addEventListener("scroll", this.handleScrollClasses.bind(this));
    }
    handleScrollClasses() {
        const scroll = window.pageYOffset;
        const navigation = this.element;
        if (navigation) {
            scroll > 50
                ? navigation.classList.add(header.scrollClass)
                : navigation.classList.remove(header.scrollClass);
        }
    }
}
exports.default = header;
header.selector = "header.header";
header.scrollClass = "scrolled";
header.mobileMenuClass = "mobileMenu";
