"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = void 0;
/**
 *  Base component class for all Block scripts
 *
 *  import this base class into your custom script and extend / inherit it to create a new block component.
 *
 *  All components must be 'installed' into the Burger framework for it to be active.
 *
 *  @see IComponent
 *  @see Framework
 */
class Component {
    /**
     * @param componentElement
     * The HTML element attached to the component; in most cases this will be the block's <section> element.
     */
    constructor(componentElement) {
        this.element = componentElement;
        if (this.element.dataset.component) {
            throw new Error("Component is already attached to a HTML Element");
        }
        this.element.dataset.component = this.constructor.name;
    }
}
exports.Component = Component;
/**
 * The HTMLSelector referring to the component's base element - the framework will use this selector to search the DOM
 * for the block's base element
 */
Component.selector = "";
